<template>
  <div class="loading" :class="{opaque}">
    <div class="wrapper">
      <Spinner/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Spinner from "@/components/Spinner";

function Component(initialize) {
  this.name = "componentLoading";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Spinner},
  mixins: [mixin],
  props: {
    opaque: Boolean
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  }
});
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.30);
  display: table;
  z-index: 1999;

  .wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &.opaque {
    background: #fff;
  }
}
</style>