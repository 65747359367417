<template>
  <div class="archive">
    <div class="wrapper">
      <div class="container">
        <div class="inner">
          <RouterView/>
          <div class="side dropdown">
            <i class="pointer fa fa-ellipsis-v" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <router-link to="/archive/applications" class="dropdown-item pointer" v-if="!$route.path.includes('/archive/applications')">나의 신청 내역</router-link>
              <a class="dropdown-item pointer" @click="logout()" v-if="$store.state.account.loggedIn">로그아웃</a>
            </div>
          </div>
        </div>
        <div class="notice">
          <span>※ 신청 및 기타 문의는 오마이컴퍼니(</span>
          <a href="tel:023882556">070-5097-2231</a>
          <span>)로 연락해주시기 바랍니다.</span>
        </div>
      </div>
    </div>
    <Footer page="archive"/>
    <Climbing/>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import Climbing from "@/components/Climbing";
import Footer from "@/components/Footer";
import router from "@/scripts/router";
import store from "@/scripts/store";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "layoutArchive";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Footer, Climbing},
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      if (router.app.$route.path.startsWith("/archive/categories")) {
        if (!router.app.$route.params.id) {
          return router.push("/error");
        }

        state.page = "timetable";
      } else if (router.app.$route.path.startsWith("/archives/")) {
        if (!router.app.$route.params.name) {
          return router.push("/error");
        }

        state.page = "application";
      }

      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }
    });

    const state = reactive({
      page: "",
      form: {},
    });

    const logout = () => {
      http.post("/api/member/logout").then(() => {
        location.reload();
      });
    };

    return {component, state, logout};
  }
});
</script>

<style lang="scss" scoped>
.archive {
  > .wrapper {
    background: $colorBackground;
    padding-top: $px50;
    padding-bottom: $px50;

    > .container {
      max-width: 576px;

      > .inner {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 4px 4px 0 0;
        position: relative;

        .side {
          position: absolute;
          top: 0;
          right: 0;
          color: #3c3c3c;
          padding: $px15 0;

          > i {
            padding: 0 $px15;
          }

          > .dropdown-menu {
            margin-right: $px15;

            > .dropdown-item {
              font-size: $px13;
              padding: $px4 $px15;

              &:active {
                background: $colorPurple;
              }
            }
          }
        }
      }

      > .notice {
        text-align: left;
        padding: $px50 $px15 0 $px15;
        font-size: $px14;
      }
    }
  }

  @media (max-width: 576px) {
    > .wrapper {
      padding: 0;
      background: none;

      > .container {
        padding: 0;

        > .inner {
          border: 0;
        }

        > .notice {
          padding-top: 0;
          padding-bottom: $px50;
        }
      }
    }
  }
}
</style>